.header-container{
  position:fixed;
  width:100%;
  z-index:999;
  -webkit-box-shadow: 0px 3px 2px rgba(50, 50, 50, 0.1);
  -moz-box-shadow:    0px 3px 2px rgba(50, 50, 50, 0.1);
  box-shadow:         0px 3px 2px rgba(50, 50, 50, 0.1);
  }

header{
  border-top:10px solid #004E70;
  background-color:#fff;
  position:relative;
}

.header-inner{
  margin:0 0 20px 0;
}
.brand{
  margin:10px 0 0 0;
}
.header-inner ul{
  list-style:none;
  float:right;
  padding:0;
  margin:0 0 0 -20px;
}
.header-inner ul li{
  float:left;
}
.header-inner ul li.active a{
  background-color:#004E70;
  color:#fff;
  border-radius:0 0 4px 4px;
}

.header-inner ul li a{
  font-family:'Cambo', sans-serif;
  display:block;
  font-size:16px;
  color:#555;
  padding:20px 30px;
  border-right:1px solid #eee;
  text-decoration:none;
}
.header-inner ul li:last-child a{
  border:0;
}

.active{
  background-color:#004E70;
  color:#fff !important;
  border-radius:0 0 4px 4px;
  text-decoration:none;
  }

.header-inner ul li a:hover{
  background-color:#004E70;
  color:#fff;
  border-radius:0 0 4px 4px;
  text-decoration:none;
}

.navbar-toggle{
  margin:20px 0 0 0;
  border:1px solid #555;
  }

@media(max-width: $screen-sm) {

  .navbar-collapse{
    width:100%;
    }

  .menu{
    float:none !important;
    margin:20px 0 0 0 !important;
    li{
      float:none !important;
      text-align:center !important;
      a{
        border:0 !important;
        border-radius:4px !important;
        }
      }
    }

}
