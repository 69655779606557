
.title{
  font-family:Cambo, sans-serif;
  text-align:center;
  font-size:2.4em;
  color:#004E70;
  margin:0 0 30px 0;
}

.body-content{

}
.slider-background{
  background-color:#004E70;
}
.slider-container{

}

.slider{
  border:1px solid #000;
  height:250px;
  margin:0 0 20px 0;
  padding:0 20px;
}

.featured{
  margin:0 0 40px 0;
  padding:0 0 20px 0;
  background-color:#004E70;
  color:#fff;
}
.featured-box{
  padding-top:40px;
  padding-left:20px;
  padding-right:20px;
  img{
    width:100%;
    border-radius:4px;
    }
  h2{
    font-family:'Cambo', sans-serif;
    font-size:1.8em;
    }
  p{
    font-size:1.2em;
    line-height:1.8em;
    }
  }

.placeholder-image{
  border:1px solid #000;
  width:100%;
  height:150px;
  margin:0 0 10px 0;
}

.team{
  h2{
    padding-top:60px;
    }
  }

.team-member{
    margin-bottom:50px;
    height:590px;
    img{
      border:1px solid #ccc;
      border-radius:4px;
      }
    h2{
      font-family:Cambo, sans-serif;
      font-size:1.6em;
      margin-bottom:5px;
      }
    h3{
      font-family:Cambo, sans-serif;
      font-size:1.4em;
      color:#888;
      margin-top:0;
      }
    p{
      font-size:1.2em;
      line-height:1.8em;
      }
  }

.help{
  padding:20px 0;
  h2{
    padding-top:50px;
    }
  .help-box {
    margin:0 0 20px 0;
    padding:60px 0 0 0;
    h2 {
      font-family:'Cambo', sans-serif;
      font-size:1.8em;
      margin: 0 0 20px 0;
      }
    p {
      line-height:1.8em;
      font-size:1.2em;
      }
    }
  .help-image{
    text-align:center;
    img{
      margin:0 auto;
      }
    }
  }

.section{
  padding: 100px 0;
}

.background-strip{
  padding-top:40px;
  background-color:#eee;
  margin-bottom:40px;
  }

.placeholder-image{
  border:1px solid #000;
  width:100%;
  height:250px;
  }

.case-study{
  margin:0 0 60px 0;
}
.placeholder-image-2{
  border:1px solid #000;
  width:100%;
  height:180px;
}

.clients{
  h2{
    padding-top:60px;
    }
  .row{
    margin-bottom:20px;
    border-bottom:1px dashed #E0E0E0;
    padding-top:40px;
    padding-bottom:40px;
    &:last-child{
      border-bottom:0;
      }
    }
  blockquote{
      padding:0 0 30px 50px;
      margin-bottom:0;
      font-size:1.3em;
      @media(max-width:$screen-xs-max){
        font-size:1.1em;
        }
      line-height:1.6em;
      color:#9B9B9B;
      font-weight:300;
      border-left:0;
      border-right:0;
      }
  //.col-md-offset-6{
  //  blockquote{
  //    background:url('../images/quote-right.png') no-repeat right 0;
  //    padding:0 90px 30px 0;
  //    }
  //  }
  }

.contact{
  padding-bottom:40px;
  h2{
    font-family:Cambo, sans-serif;
    }
  h3{
    font-family:Cambo, sans-serif;
    font-weight:300;
    }

  .contact-details{
    background:url('../images/stamp.png') no-repeat right center;
    padding:20px;
    h3{
      margin:0 0 10px 0;
      }
    address{
      display:block;
      font-size:1.2em;
      font-weight:300;
      line-height:1.9em;
      margin:0;
      padding-left:30px;
      margin-bottom:20px;
      }
    span{
      font-size:1.2em;
      font-weight:300;
      line-height:1.9em;
      padding-left:30px;
      }
    }
  label{
    font-weight:300;
    font-size:1.2em;
    }
  .wpcf7{
    margin-top:20px;
    }
  .wpcf7-text, .wpcf7-textarea{
    font-size:1.3em;
    font-weight:300;
    padding:20px;
    border:0;
    background-color:rgba(#004E70, 0.1);
    box-shadow:none;
    &:focus{
      background-color:rgba(#004E70, 0.2);
      }
    }
  .wpcf7-submit{
    background-color:#004E70 !important;
    border-color:#004E70 !important;
    padding:10px 30px;
    }

    @media(max-width:$screen-xs-max){
      .form-group{
        padding:0 10px !important;
        }
      .wpcf7-submit{
        margin-left:10px;
        }
    }
  }

.google-maps{
  position:relative;
  padding-bottom:40%; // This is the aspect ratio
  margin-bottom:20px;
  height:0;
  overflow:hidden;
  border:1px solid #ccc;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    }
  }

.overlay {
  background:transparent;
  position:relative;
  width:640px;
  height:300px; /* your iframe height */
  top:300px;  /* your iframe height */
  margin-top:-300px;  /* your iframe height */
}

  .footer-inner{
    border-top:1px solid #eee;
    padding-top:20px;
    margin-top:40px;
    p{
      font-size:0.9em;
      color:#888;
      }
    }